var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _c("main", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "group mb-5" }, [
          _c("div", { staticClass: "steps mb-4 mb-lg-0" }, [
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", { staticClass: "completed" }, [_vm._v(" Brand ")]),
              _c("li", { staticClass: "completed" }, [_vm._v("Category")]),
              _c(
                "li",
                {
                  staticClass: "active",
                  class: { completed: _vm.isSubCategorySelected }
                },
                [_vm._v("Sub-Category")]
              ),
              _c("li", [_vm._v("View & Compare")])
            ])
          ])
        ]),
        _c("header", { staticClass: "content-header" }, [
          _c("h1", { staticClass: "text-primary mb-40 theme-text" }, [
            _vm._v("Select Sub-Category")
          ]),
          _c("div", { staticClass: "filter1" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-7" }, [
                _c(
                  "div",
                  { staticClass: "filter-wrapper" },
                  [
                    _c("span", [_vm._v("Categories:")]),
                    _c("CategoriesDropdown", {
                      on: { changeCategory: _vm.categoryChanged }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "content-body" }, [
          _c(
            "div",
            { staticClass: "cards four-column-card d-flex" },
            _vm._l(_vm.subCategories, function(subCategory) {
              return _c(
                "div",
                {
                  key: subCategory.id,
                  staticClass: "card",
                  class: {
                    active:
                      _vm.isSubCategorySelected &&
                      _vm.selectedSubCategory.id == subCategory.id
                  },
                  attrs: {
                    id:
                      _vm.sanitizeInput(subCategory.name) +
                      "-sub-category-button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectSubCategory(subCategory)
                    }
                  }
                },
                [
                  _c("figure", [
                    _c("img", {
                      attrs: { src: subCategory.icon, alt: subCategory.name }
                    }),
                    _c("figcaption", [_vm._v(_vm._s(subCategory.name))])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }