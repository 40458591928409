<template>
  <BaseLayout>
    <main id="content">
          <div class="container">
             <div class="group mb-5">
              <div class="steps mb-4 mb-lg-0">
                <ul class="list-unstyled">
                  <li class="completed"> Brand </li>
                  <li class="completed">Category</li>
                  <li class="active" v-bind:class="{ completed : isSubCategorySelected }">Sub-Category</li>
                  <li>View &amp; Compare</li>
                </ul>
              </div>
            </div>

            <header class="content-header">
              <h1 class="text-primary mb-40 theme-text">Select Sub-Category</h1>
              <div class="filter1">
                <div class="row">
                  <div class="col-lg-7">
                    <div class="filter-wrapper">
                      <span>Categories:</span>
                      <CategoriesDropdown v-on:changeCategory="categoryChanged" />
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div class="content-body">
              <div class="cards four-column-card d-flex">
                <div class="card" v-bind:class="{ active : (isSubCategorySelected && selectedSubCategory.id == subCategory.id) }" v-for="subCategory in subCategories" :key="subCategory.id" v-on:click="selectSubCategory(subCategory)" :id="sanitizeInput(subCategory.name) + '-sub-category-button'">
                  <figure>
                    <img :src="subCategory.icon" :alt="subCategory.name">
                    <figcaption>{{ subCategory.name }}</figcaption>
                  </figure>
                </div>
              </div>
            </div>
           
          </div>
        </main>
        </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/base";
import CategoriesDropdown from "@/components/CategoriesDropdown";
import CategoryService from "@/services/category.service";

export default {
  name: "SelectSubCategory",
  components: {
    BaseLayout,
    CategoriesDropdown,
  },
  data: function () {
    return {
      subCategories: [],
    };
  },
  mounted() {
    this.getSubCategories();
    this.$store.dispatch("subCategory/diselect");
    this.$store.dispatch("filters/remove");
  },
  computed: {
    selectedCategory() {
      return this.$store.state.category.selectedCategory;
    },
    isCategorySelected() {
      return this.$store.state.category.selectedCategory != null;
    },
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    isSubCategorySelected() {
      return this.$store.state.subCategory.selectedSubCategory != null;
    },
  },
  methods: {
    getSubCategories: function () {
        CategoryService.children(this.selectedCategory.id).then((list) => {
        this.subCategories = list;
      });
    },

    sanitizeInput: function(input) {
      var sanitizedData = "";
      // Change to lower case
      var inputLower = input.toLowerCase();
      // Letter "e"
      sanitizedData = inputLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      sanitizedData = sanitizedData.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      sanitizedData = sanitizedData.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      sanitizedData = sanitizedData.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      sanitizedData = sanitizedData.replace(/đ/gi, 'd');
      // Trim the last whitespace
      sanitizedData = sanitizedData.replace(/\s*$/g, '');
      // Change whitespace to "-"
      sanitizedData = sanitizedData.replace(/\s+/g, '-');
      
      return sanitizedData;
    },

    selectSubCategory: function (subCategory) {
      this.$store.dispatch("subCategory/select", subCategory);
      this.$router.push("/products")
    },
    
    categoryChanged: function() {
        this.getSubCategories();
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>